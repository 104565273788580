<template>
    <div
        class="detail"
        v-if="info"
    >
        <div class="title mr-b2">{{info.club.name}}</div>
        <div class="desc mr-b4">
            {{info.club.desc}}
        </div>
        <div class="_title mr-b1">{{"研究方向"|language}}</div>
        <div class="desc mr-b4">
            {{info.club.interest}}
        </div>
        <div class="_title mr-b2">{{"指导教师"|language}}</div>
        <div class="teacher-list">
            <div
                class="_item mr-b2"
                v-for="(item,index) in info.club.mentors"
                :key="index"
            >
                <img
                    v-real-img="item.avatar.src"
                    alt=""
                    class="_img mr-r2"
                >
                <div class="_content">
                    <div class="_name">{{item.name}} {{item.title}}</div>
                    <div class="_desc">{{item.desc}}</div>
                </div>
            </div>
        </div>
        <div class="_title mr-b2">{{"人员组成"|language}}</div>
        <div class="line-content">
            <div class="left">{{"部长"|language}}：</div>
            <div class="info">{{info.club.minister.name}} {{info.club.minister.email}}</div>
        </div>
        <div class="line-content">
            <div class="left">{{"技术负责"|language}}：</div>
            <div class="info">{{info.club.techDirector.name}} {{info.club.techDirector.email}}</div>
        </div>
        <div class="line-content mr-b4">
            <div class="left">{{"成员"|language}}：</div>
            <div class="info">{{info.club.members.join('、')}}</div>
        </div>
        <div class="row-container">
            <div class="plan">
                <div class="_title mr-b2">{{"创新计划"|language}}</div>
                <ul class="plan-list">
                    <li
                        class="plan-item click-op"
                        v-for="(item,index) in info.club.plan"
                        :key="index"
                    >{{item}}</li>
                </ul>
            </div>
            <div class="plan mr-l4">
                <div class="_title mr-b2">{{"最新动态"|language}}</div>
                <ul class="plan-list">
                    <li
                        class="plan-item click-op"
                        v-for="(item,index) in info.news"
                        :key="item._id"
                    >{{item.title}}</li>
                </ul>
                <div
                    class="more click-op mr-t3"
                    @click="getMore"
                    v-if="total>0"
                >
                    <!-- v-if="info.news.length>=6" -->
                    <div class="line"></div>
                    <div class="text mr-l1">{{"查看更多"|language}}</div>
                    <Icon
                        type="ios-arrow-forward"
                        :size="16"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { languageTranslation } from "../../assets/util/language";
export default {
  data() {
    return {
      info: null,
      pageSize: 6,
      pageNum: 0,
      total: 0
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    getMore() {
      if (this.info.news.length < 6) {
        return this.$Message.error(languageTranslation("没有更多的动态啦"));
      }
      this.fetchNews();
    },
    async fetchNews() {
      let res = await this.$http.get(
        `/innovation/news/more?clubId=${this.$route.params.id}&type=ClubModel&pageSize=${this.pageSize}&pageNum=${this.pageNum}`
      );
      this.pageNum += 1;

      this.$set(this.info, "news", [...this.info.news, ...res.result]);
      this.total = res.result.length;
    },
    async fetchData() {
      this.info = await this.$http.get(
        `/innovation/club/detail/${this.$route.params.id}`
      );
      this.total = this.info.news.length;
    }
  }
};
</script>

<style lang="less" scoped>
.detail {
  text-align: left;
  .title {
    opacity: 0.9;
    font-size: 30px;
    line-height: 32px;
  }
  .desc {
    opacity: 0.6;
    font-size: 16px;
    line-height: 29px;
  }
  ._title {
    opacity: 0.9;
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
  }
  .teacher-list {
    ._item {
      display: flex;
      align-items: center;
      ._img {
        object-fit: cover;
        width: 108px;
        height: 108px;
        background: #d8d8d8;
      }
      ._content {
        flex: 1;
        font-size: 16px;
        ._name {
          opacity: 0.9;
          line-height: 29px;
        }
        ._desc {
          opacity: 0.6;
          line-height: 29px;
        }
      }
    }
  }
  .line-content {
    display: flex;
    align-items: center;
    text-align: left;
    .left {
      width: 100px;
      opacity: 0.6;
      font-size: 16px;
      line-height: 29px;
    }
    .info {
      opacity: 0.9;
      font-size: 16px;
      color: #000;
      line-height: 29px;
    }
  }
  .row-container {
    margin-bottom: 80px;
    display: flex;
    align-items: flex-start;
    .plan {
      ._title {
        opacity: 0.9;
        font-size: 22px;
        line-height: 32px;
      }
      .plan-list {
        width: 400px;
        margin-left: 15px;
        .plan-item {
          opacity: 0.9;
          font-size: 16px;
          line-height: 29px;
        }
      }
      .more {
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 12px;
          background-color: #f55e5a;
        }
        .text {
          margin-right: 5px;
          opacity: 0.9;
          font-size: 16px;
          line-height: 29px;
        }
      }
    }
  }
}
</style>